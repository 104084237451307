@import '../../../theme/bootstrap-custom-variables';

.proposalListRow {
    background-color: #fff;
    border-left: 1px solid $table-border-color;
    border-right: 1px solid $table-border-color;
    border-top: 1px solid $table-border-color;
    padding-bottom: 20px;
    padding-top: 20px;

    &:last-child {
        border-bottom: 1px solid $table-border-color;
    }

    &:hover {
        background-color: $table-bg-hover;
        cursor: pointer;

        .primary {
            color: darken($btn-primary-bg, 10%);
        }

        .success {
            color: darken($btn-success-bg, 10%);
        }

        .info {
            color: darken($btn-info-bg, 10%);
        }

        .warning {
            color: darken($btn-warning-bg, 10%);
        }

        .danger {
            color: darken($btn-danger-bg, 10%);
        }

        .muted {
            color: darken($gray-light, 10%);
        }

        .royal {
            color: darken($btn-royal-bg, 10%);
        }

        .dark-gray {
            color: darken($gray-dark, 10%);
        }
    }
}

.titleRow {
    margin-bottom: 5px;
}

.title {
    display: inline-block;
    font-size: 20px;
    line-height: normal;
    margin: 0;
    padding: 0;
}

.statusText {
    font-weight: bold;
    margin-top: 0;
}

.dateIcon {
    color: $btn-warning-bg;
    margin-right: 3px;
}

.dates {
    margin-bottom: 0;
}

.primary {
    color: $btn-primary-bg;
}

.success {
    color: $btn-success-bg;
}

.info {
    color: $btn-info-bg;
}

.warning {
    color: $btn-warning-bg;
}

.danger {
    color: $btn-danger-bg;
}

.muted {
    color: $gray-light;
}

.royal {
    color: $btn-royal-bg;
}

.dark-gray {
    color: $color-fg-secondary;
}

.menuDropdown {
    text-align: right;
    position: absolute;
    right: 0;
    top: 20px;
}

.container {
    position: relative;
}

.menuDropdownBtn {
    padding-right: 10px;

    button {
        border-radius: 50%;
        font-size: 0.8em;
        height: 25px;
        padding: 4px;
        width: 25px;
    }
}

.tags {
    margin-top: 3px;
}

@media (max-width: $screen-xs-max) {
    .gov,
    .detail {
        margin-top: 5px;
        text-align: center;
    }

    .tags {
        margin-top: 5px;
    }
}
