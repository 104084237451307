@import '../../theme/bootstrap-custom-variables';

.contact {
    strong {
        font-weight: normal;
    }
}

.publicLabel {
    @media (min-width: $screen-xs-min) {
        display: inline-block;
        line-height: 1.8em;
    }
}

.renewalRow {
    margin-bottom: 5px;
    margin-top: 5px;
}

.toggleText {
    margin-top: 1px;
}

.vendorDataCell {
    align-items: center;
    display: inline-flex;
}

.approvalButton {
    padding: 4px 8px !important;
}
