@import '~@opengov/capital-style/scss/tokens';

.uploadBox {
    label {
        margin-bottom: $unit-1;
    }
    border: 0;
    font-family: $base-font;
    padding-right: $unit-1;
    margin-bottom: $unit-2;

    .dropzone {
        border: 0;
        background: none;
        padding-top: $unit-2;
        padding-bottom: $unit-2;
    }

    .maxSize {
        color: $color-gray-700;
        font-size: $font-size-small;
        font-weight: $font-weight-regular;
        line-height: $line-height-minimum;
    }

    :global(.progress) {
        height: $unit-half;
        margin-bottom: $unit-1;
        background-color: $color-action-50;
        border-radius: $border-radius-default;

        :global(.progress-bar) {
            background-color: $color-action-800;
            border-radius: $border-radius-default;
        }
    }

    .scanLinkButton:focus {
        outline: none;
    }
}

.uploadInfo {
    margin-top: $unit-2;
    text-align: center;
}

.dropPanelContainer {
    border: 1px solid $color-gray-200;
    border-radius: $border-radius-default;
    background: $color-gray-50;

    > div {
        border-radius: $border-radius-default;
        border: 1px dashed $color-gray-200;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: $unit-1;
    }
}

.buttonContainer {
    display: flex;
    flex-direction: column;
}

.fakeInputData {
    font-family: $base-font;
    font-size: $font-size-default;
    line-height: $line-height-default;

    .label {
        font-weight: $font-weight-medium;
    }

    .value {
        font-weight: $font-weight-regular;
    }
}

.listFormDropzoneContainer {
    padding: 0;

    .dropzone {
        border: 0;
        padding: 0;
    }

    .uploadReplacementButton {
        background: none;
        color: $color-action-700;
        padding: 0 !important;
    }
}

.attachmentList {
    padding-left: 0;
    padding-right: $unit-2;
    margin-bottom: $unit-2;
    margin-top: $unit-2;
}

.attachmentItem {
    border-bottom: 1px solid $color-gray-200;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding-top: $unit-half;
    padding-bottom: $unit-half;

    a {
        text-decoration: none;
        font-weight: $font-weight-regular;
    }
}

// sometimes the attachment list first item is a label, so we need to ignore it
/* stylelint-disable-next-line selector-no-qualifying-type */
.attachmentList div.attachmentItem:first-of-type {
    border-top: 1px solid $color-gray-200;
}

.deleteButton:focus {
    outline: none;
}

.deleteButton {
    padding-right: 0 !important;
}

.uploadButtonAsLink {
    color: $color-action-900 !important;
    font-size: $font-size-default !important;
    font-weight: $font-weight-regular !important;
    text-decoration: underline !important;
    padding: 0 !important;
    transition: none !important;
    // To prevent the button from showing a border and background on hover (button as link, instead of a text button)
    &:hover,
    &:focus {
        border-color: transparent !important;
        background: none !important;
        transition: none !important;
    }
}

.uploadingLabel {
    font-family: $base-font-alt;
    font-size: $font-size-minimum;
    color: $color-fg-secondary;
}
