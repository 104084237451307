@import '../../theme/bootstrap-custom-variables';

.gridWrapper {
    border-bottom: 4px solid $brand-primary;
}

.panel {
    border: 0 !important;
    box-shadow: none !important;
    margin-bottom: 0 !important;
}

.panelBody {
    padding: 0 !important;
}
