@import '../../theme/bootstrap-custom-variables';
@import '~@opengov/capital-style/scss/tokens';

.buttonWithDividerContainer {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.buttonWithDivider {
    margin: 0 $unit-2;
}

.divider {
    border: 0;
    border-top: 1px solid $panel-default-border;
    flex-grow: 1;
}
