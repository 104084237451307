@import '~@opengov/capital-style/scss/tokens';

.actionPanelItem {
    display: flex;
    flex-direction: column;
    gap: $unit-half;

    label {
        font-size: $font-size-small;
        font-weight: $font-weight-semi-bold;
        line-height: $line-height-minimum;
        margin: 0;
    }
}

.actionButtons {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: $unit-1;
    margin-left: auto;
}
