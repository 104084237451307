@import '~@opengov/capital-style/scss/tokens';

.attachment {
    cursor: pointer;
    word-wrap: break-word;
}

.container {
    clear: both;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.date {
    font-size: 0.8em;
    font-style: italic;
    line-height: 0.8em;
    margin-top: 2px;
}

.deleteError {
    margin-top: 3px;
}

.listItemStyle {
    margin-bottom: 6px;

    .date {
        padding-bottom: 2px;
    }
}

.fileIcon {
    font-size: 1.05em !important;
    margin-right: 6px;
}

.deleteButton,
.editButton {
    margin-left: 5px;
    padding: 0 !important;
    vertical-align: center;
}

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.inputContainer {
    justify-content: center;
    height: 100%;
    width: 30vw;
}

.useOpenGovStyle {
    border-top: 1px solid $color-gray-200;
    font-family: $base-font;
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    line-height: $line-height-default;
    padding-bottom: $unit-1;
    padding-top: $unit-1;

    .link {
        color: $color-action;
    }

    &.last {
        border-bottom: 1px solid $color-gray-200;
    }
}
