@import '../../theme/bootstrap-custom-variables';
@import '~@opengov/capital-style/scss/tokens';

.openGovStyle {
    font-family: $base-font;
}

.progressBar {
    height: 12px !important;
    margin-bottom: 0 !important;
}

.attachmentError {
    color: $state-danger-text;
    margin-top: 3px;
}
