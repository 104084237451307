@import '../../../../../theme/bootstrap-custom-variables';

.container {
    border-top: 1px solid $panel-header-border-gray;
    margin-top: 20px;
    padding-top: 15px;
    text-align: center;
}

.navButton {
    display: inline-block;
    width: 70px;
}

.nextBtnHighlight {
    @extend .navButton;
    color: $btn-primary-color !important;
}
