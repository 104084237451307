.container {
    display: block;
    margin-bottom: 10px;
    margin-top: 5px;
}

.adminButton {
    display: inline !important;
}

.adminHelp {
    margin-bottom: 3px;
}
