@import '~@opengov/capital-style/scss/tokens';

.user {
    display: flex;
    gap: $unit-1;

    .position {
        color: $color-fg-secondary;
        font-family: $base-font-condensed;
        font-size: $font-size-minimum;
        line-height: $unit-2;
    }
}
