@import '../../theme/bootstrap-custom-variables';

$circle-size: 84px;

.statusCircle {
    border-radius: 50%;
    color: #fff;
    height: $circle-size;
    line-height: $circle-size;
    text-align: center;
    width: $circle-size;

    &.primary {
        background-color: $btn-primary-bg;
        border-color: $btn-primary-border;

        &:hover {
            background-color: darken($btn-primary-bg, 10%);
        }
    }

    &.success {
        background-color: $btn-success-bg;
        border-color: $btn-success-border;

        &:hover {
            background-color: darken($btn-success-bg, 10%);
        }
    }

    &.info {
        background-color: $btn-info-bg;
        border-color: $btn-info-border;

        &:hover {
            background-color: darken($btn-info-bg, 10%);
        }
    }

    &.warning {
        background-color: $btn-warning-bg;
        border-color: $btn-warning-border;

        &:hover {
            background-color: darken($btn-warning-bg, 10%);
        }
    }

    &.danger {
        background-color: $btn-danger-bg;
        border-color: $btn-danger-border;

        &:hover {
            background-color: darken($btn-danger-bg, 10%);
        }
    }

    &.muted {
        background-color: $gray-light;
        border-color: $gray-light;

        &:hover {
            background-color: darken($gray-light, 10%);
        }
    }
}
