@import '../../../../theme/bootstrap-custom-variables';
@import '../../../../components/ProposalDetails/index';

.addProposalBtn {
    float: right;
}

.proposalDocTitle {
    margin-bottom: 20px;
    text-align: center;
}

@media (max-width: $screen-sm-max) {
    .addProposalBtn {
        float: none;
        margin-bottom: 10px;
    }
}
