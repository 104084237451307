@import '~@opengov/capital-style/scss/tokens';

.optionsContainer {
    padding: 0;
    padding-left: $unit-3;
}

.checkbox {
    height: 18px;
    width: 18px;
}

.checkboxText {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: $unit-35;
}

.checkboxLabel {
    color: $color-fg-primary;
    font-size: $font-size-small;
    font-weight: $font-weight-medium;
    white-space: nowrap;
}

.component {
    padding-top: calc($unit-1 + $unit-half); // 12px
}
