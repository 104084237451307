@import '~@opengov/capital-style/scss/tokens';

.flags {
    padding: $unit-3 0 0;

    .flag:first-of-type {
        border-top: 1px solid $color-fg-border-secondary;
    }

    .flag {
        border-bottom: 1px solid $color-fg-border-secondary;
        color: $color-fg-primary;
        display: flex;
        font-size: $unit-2;
        justify-content: space-between;
        padding: 12px $unit-2;

        :global(.form-group) {
            margin: 0;

            label {
                margin-bottom: 0;
            }
        }

        &:last-of-type {
            border-bottom: 0;
        }
    }
}

.heading {
    font-size: $font-size-default;
    font-weight: $font-weight-semi-bold;
}

.radioGroup {
    display: flex;
    gap: $unit-3;
}

.error {
    color: $color-fg-error !important;
    margin-top: $unit-1;
}
