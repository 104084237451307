@import '../../theme/bootstrap-custom-variables';

.container {
    margin-bottom: 20px;
}

.logoContainer {
    float: left;
}

.logo {
    border: 1px solid $gray-light;
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 30px;
    max-width: 120px;
    padding: 3px;
}

.cityText {
    font-size: 1.3em;
    margin-top: 3px;
}

.vendorText {
    margin-bottom: 0;
    margin-right: 4px;
}

.statusText {
    margin-bottom: 3px;

    .retentionPolicyButton {
        padding-left: 12px;
    }

    strong {
        font-size: 1.1em;
    }
}

.timezoneText {
    font-size: 0.9em;
}

.controlsContainer {
    float: right;
    padding-left: 5px;
    padding-right: 20px;
    text-align: right;
}

.publicText {
    display: inline-block;
    font-size: 16px;
    margin-right: 5px;
    margin-top: -17px;
    vertical-align: middle;
}

.publicButton {
    margin-bottom: 15px;
}

.simpleControlsContainer {
    margin-top: 10px;
}

@media (max-width: $screen-xs-max) {
    .container {
        text-align: center;
    }

    .controlsContainer {
        float: none;
    }

    .logo {
        margin: 0 0 10px;
    }

    .logoContainer {
        float: none;
    }

    .cityText {
        font-size: 1.1em;
    }

    .statusText {
        strong {
            font-size: 1em;
        }
    }
}
