@import '~@opengov/capital-style/scss/tokens';

.container {
    align-items: center;
    color: $color-action;
    display: flex;
    font-family: $base-font;
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    line-height: $line-height-default;

    .back {
        cursor: pointer;
        flex: 1;
    }

    .next {
        cursor: pointer;
        flex: 1;
        text-align: right;
    }

    .navButton {
        color: $color-action;

        &:hover {
            color: $color-action;
        }
    }

    .sectionName {
        font-weight: $font-weight-bold;
    }
}
