@import '~@opengov/capital-style/scss/tokens';

.title {
    color: $color-gray-1000;
    font-family: $base-font;
    font-size: $font-size-xlarge;
    font-weight: $font-weight-bold;
    line-height: $line-height-medium;
}

.subtitle {
    color: $color-gray-700;
    font-family: $base-font;
    font-size: $font-size-small;
    font-weight: $font-weight-regular;
    line-height: $line-height-minimum;
}

.sectionTitle {
    color: $color-gray-1000;
    font-family: $base-font;
    font-size: $font-size-medium;
    font-weight: $font-weight-medium;
    line-height: $line-height-medium;
    margin-bottom: $unit-1;
}

.subsectionTitle {
    color: $color-gray-1000;
    font-family: $base-font;
    font-size: $font-size-default;
    font-weight: $font-weight-medium;
    line-height: $line-height-default;
}

.subsectionBrief {
    color: $color-gray-700;
    font-family: $base-font;
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    line-height: $line-height-default;
}

.uploadBox {
    border: 1px solid $color-gray-200;
    font-family: $base-font;
    padding-left: $unit-1;
    padding-right: $unit-1;

    .dropzone {
        background-color: $color-white !important;
        border: 0;
        padding: $unit-2;
    }

    .maxSize {
        color: $color-gray-700;
        font-size: $font-size-small;
        font-weight: $font-weight-regular;
        line-height: $line-height-minimum;
    }

    .success {
        color: $color-success-700;
        font-family: $base-font;
        font-size: $font-size-base;
        font-weight: $font-weight-medium;
        line-height: $line-height-default;
    }
}

.uploadInfo {
    font-style: italic;
    margin-top: $unit-2;
    text-align: center;
}

.panelGroup {
    font-family: $base-font;

    .panelHeading {
        background-color: $color-white;
        padding: $unit-2 $unit-3;

        &.panelHeadingOpen {
            background-color: $color-primary-hover-light;
        }

        .chevron {
            color: $color-gray-700;
            margin-right: $unit-1;
        }

        .panelTitle {
            color: $color-primary-brand;
            font-weight: $font-weight-medium;
        }

        :global(.label-default) {
            font-size: $font-size-minimum;
            font-weight: $font-weight-regular;
        }

        :global(.panel-title) {
            a:hover,
            a:focus {
                text-decoration: none;
            }
        }

        .timestamp {
            font-size: $font-size-small;
            font-weight: $font-weight-regular;
            line-height: $line-height-minimum;
        }
    }

    .panelBody {
        padding: $unit-3;

        label {
            color: $color-gray-700 !important;
            font-size: $font-size-small;
        }
    }

    .separatorsContainer {
        border-top: 1px solid $color-gray-200;
        margin-top: $unit-3;
        padding-top: $unit-2;

        .showHideButton {
            background: none;
            padding-left: 0;

            i {
                margin-right: $unit-1;
            }
        }

        .preview {
            .previewTitle {
                color: $color-gray-700;
                font-size: $font-size-small;
                font-weight: $font-weight-medium;
                line-height: $line-height-default;
            }

            .previewOutput {
                color: rgb(0, 0, 0);
                font-size: $font-size-base;
                font-weight: $font-weight-medium;
                line-height: $line-height-default;
                margin-top: $unit-1;
            }
        }
    }
}

.allowedVariablesContainer {
    background-color: $color-gray-100;
    border: 1px solid $color-gray-200;
    border-radius: $unit-half;
    color: $color-gray-700;
    font-family: $base-font;
    font-size: $font-size-small;
    font-weight: $font-weight-regular;
    line-height: $line-height-default;
    margin-bottom: $unit-3;
    margin-top: $unit-3;
    padding: $unit-2;

    .variableTitle {
        font-weight: $font-weight-medium;
    }

    .variableSubtitle {
        line-height: $line-height-minimum;
    }

    .variableValue {
        color: #000;
        font-size: $font-size-base;
        margin-bottom: $unit-half;
        text-transform: uppercase;
    }
}

.fakeInputData {
    font-family: $base-font;
    font-size: $font-size-default;
    line-height: $line-height-default;

    .label {
        font-weight: $font-weight-medium;
    }

    .value {
        font-weight: $font-weight-regular;
    }
}

.listFormDropzoneContainer {
    .dropzone {
        background-color: $color-white !important;
        border: 0;
        padding: 0;
    }

    .uploadReplacementButton {
        background: none;
        color: $color-primary-brand;
        padding: 0;
    }
}

.dropzoneContainer {
    padding: 0 !important;
}
