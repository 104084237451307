.backButton {
    color: #337ab7;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 0 0 15px;

    .backArrow {
        padding-right: 5px;
    }
}

.container {
    padding-bottom: 50px;
}

.heading {
    display: flex;
    justify-content: space-between;

    .title {
        font-size: 24px;
        font-weight: 500;
    }
}

.panel {
    display: flex;
    justify-content: center;
    width: 100%;
}

.nav {
    padding-bottom: 15px;
    padding-top: 50px;

    .counter {
        font-size: 0.9em;
        font-weight: normal;
    }
}
