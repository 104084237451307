@import '~@opengov/capital-style/scss/tokens';

.approvalModal {
    :global(.modal-dialog) {
        padding-left: $unit-1;

        span {
            font-weight: $font-weight-bold;
        }
    }

    :global(.modal-header),
    :global(.modal-footer) {
        border: 0;
    }

    :global(.modal-body) {
        padding-left: $unit-4;
    }
}

.warningIcon,
.overBudget {
    color: $color-fg-error;
}
