@import '~@opengov/capital-style/scss/tokens';

.section {
    color: $color-gray-1000;
    cursor: pointer;
    font-family: $base-font;
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    padding: $unit-2 $unit-3;

    .complete {
        color: $color-success-700;
    }

    .incomplete {
        color: $color-error-700;
    }
}

.borderBottom {
    border-bottom: 1px solid $color-gray-200;
}

.banner {
    align-items: center;
    color: $color-gray-1000;
    display: flex;
    font-family: $base-font;
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    line-height: $line-height-default;
    margin-bottom: $unit-3;
    padding: $unit-2;

    .title {
        font-weight: $font-weight-medium;
    }

    i {
        font-size: $font-size-xxlarge;
    }
}

.successBanner {
    background-color: $color-success-50;
    border: 1px solid $color-success-600;

    i {
        color: $color-success-600;
    }
}

.errorBanner {
    background-color: $color-error-50;
    border: 1px solid $color-error-600;

    i {
        color: $color-error-600;
    }
}

.container {
    background-color: $color-gray-50;
    border-top: 1px solid $color-gray-200;
    color: $color-gray-1000;
    font-family: $base-font;
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    line-height: $line-height-default;

    .header {
        background: $color-white;
        border-bottom: $border-gray-200;
    }

    .content {
        padding: $unit-4 $unit-15;

        h3 {
            color: $color-gray-1000;
        }

        .sectionsContainer {
            background-color: $color-white;
            border: 1px solid $color-gray-200;
            border-radius: $unit-half;
            margin-top: $unit-1;
        }
    }

    .footer {
        background-color: $color-white;
        border-top: 1px solid $color-gray-200;
        padding: $unit-2 $unit-4 $unit-2 $unit-2;
    }
}

.contentBlock {
    padding: 0 !important;

    :global(.content-block-main) {
        padding: 0 !important;
    }
}

.toggle {
    :global(.form-group) {
        margin-bottom: 0;

        :global(.control-label) {
            align-items: center;
            margin-bottom: 0;
        }
    }
}
