@import '../../../../../../theme/bootstrap-custom-variables';
@import '~@opengov/capital-style/scss/tokens';

.container {
    padding-bottom: $unit-3;
}

.sortingContainer {
    align-items: center;
    display: flex;
    gap: $unit-1;
    margin-bottom: 0 !important;
}

.select {
    width: $unit-25;
}

.filtersContainer {
    align-items: center;
    display: flex;
    gap: $unit-3;
}

.filtersButton {
    height: 34px;
}

.separator {
    border-top: 1px solid $color-fg-border-primary;
    margin-top: $unit-2;
}

.searchContainer {
    margin-bottom: 0;
    width: 100%;
}

.searchContainer span span {
    background-color: $color-white;
}

.searchContainer span input::placeholder {
    color: $color-gray-500;
    font-weight: $font-weight-regular;
}

.label {
    font-weight: $font-weight-regular;
    white-space: nowrap;
}

.searchIcon {
    color: $color-fg-secondary;
}

.filterGroup {
    margin-bottom: $unit-2;
}

.requestsOlderThanDataContainer {
    align-items: center;
    display: flex;
    gap: $unit-2;
}

.requestsOlderThanCircle {
    border-radius: 100%;
    min-height: 18px;
    min-width: 18px;
}

.closeChartSelectionBtn {
    align-items: center;
    display: flex;
    gap: $unit-half;
    padding: $unit-half $unit-1;
}

@media (max-width: $screen-sm-max) {
    .filtersContainer {
        margin-top: $unit-2;
    }
}

@media (max-width: $screen-xs-max) {
    .filtersContainer {
        flex-direction: column;
        gap: $unit-2;
    }
}

.sortingAndFilters {
    display: flex;
    gap: $unit-3;
}

.search {
    width: 100%;
    display: flex;
    gap: $unit-3;
}
