@import '../../../../../../../../theme/bootstrap-custom-variables';

.doneButton {
    text-align: center;
}

.questionLogicIcon {
    margin-top: 5px;
}

.showInstructionsButton {
    margin-top: 5px;
    padding: 2px !important;
}

@media (min-width: $screen-lg) {
    .doneButton {
        margin-top: 24px;
    }
}
