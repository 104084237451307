@import '~@opengov/capital-style/scss/tokens';

.subscribedVendorCertificationChart {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    gap: 10px;

    :global(.MuiTypography-root) {
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    }

    .header {
        gap: $unit-1;

        .exportButton {
            height: $unit-4;
        }

        .headingText {
            font-size: 18px;
            font-weight: $font-weight-regular;
        }

        .description {
            color: $color-fg-secondary;
            font-size: $font-size-small;
        }
    }
}
