.titleContainer {
    margin-bottom: 20px;
}

.title {
    display: inline-block;
    margin-right: 8px;
}

.statusLabel {
    position: relative;
    top: 7px;
    vertical-align: top !important;
}

.typeLabel {
    font-style: italic;
}

.contentItem {
    margin-bottom: 5px;
}

.connectedClients {
    font-size: 0.9em;
    margin-right: 0;
    margin-top: 5px;
}
