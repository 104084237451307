@import '../../../theme/bootstrap-custom-variables';

.profilePicture {
    margin-right: 5px !important;
    vertical-align: top;
}

.statusIcon {
    font-size: 1.4em;
}

.userNameText {
    display: inline-block;
    line-height: 1.2em;
}

.vendorAssignedUser {
    margin-top: 5px;
}

.responseStatus {
    font-style: italic;
    margin-top: 3px;
}

.starIcon {
    color: $brand-warning;
    margin-right: 3px;
}

@media (min-width: $screen-md-min) {
    .unassignedContainer {
        width: 160px;
    }
}

@media (max-width: $screen-xs-max) {
    .lastUpdateCol {
        margin-left: 5px;
    }

    .xsRow {
        margin-bottom: 10px;
    }

    .container {
        text-align: center;
    }
}
