@import '../../../theme/bootstrap-custom-variables';

.closeOutIcon {
    color: $btn-warning-bg;
    font-size: 16px;
    margin-left: 8px;
    vertical-align: middle;
}

.logo {
    border: 1px solid $panel-default-border;
    float: left;
    margin-right: 8px;
    vertical-align: top;
    width: 42px;
}

.logoText {
    overflow: hidden;
}

.pauseIcon {
    @extend .closeOutIcon;
    color: $state-danger-text;
}

.projectRow {
    cursor: pointer;
    padding-bottom: 2px;
    padding-top: 2px;
}

.rowWrapText {
    white-space: normal !important;
}

.statusLabel {
    font-size: 12px;
}

.hiddenLink {
    color: rgb(19, 21, 23);

    &:hover {
        color: rgb(19, 21, 23);
    }
}
