@import '../../../theme/bootstrap-custom-variables';
@import '../../../components/ProposalDetails/index';

.updateError {
    color: $state-danger-text;
    margin-top: 5px;
}

.unrevisableMessage {
    color: $text-muted;
    margin-top: 5px;
}

.editBtn {
    text-align: right;
}

@media (max-width: $screen-sm-max) {
    .editBtn {
        margin-bottom: 10px;
    }
}
