.comingSoonContainer {
    margin: 0 auto;
    position: relative;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
}

.comingSoonImage {
    padding: 30px 5%;
    width: 100%;
}

.comingSoonText {
    color: #fff;
    font-size: 30px;
    left: 50%;
    margin-left: -35%;
    position: absolute;
    text-align: center;
    top: 200px;
    width: 70%;
}
