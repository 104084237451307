@import '~@opengov/capital-style/scss/tokens';

.formContentContainer {
    align-self: stretch;
    background: $color-bg-primary;
    border: 1px solid $color-fg-border-primary;
    border-radius: $unit-half;
    padding: $unit-3 0;

    .formTitleContainer {
        align-items: flex-start;
        display: flex;
        gap: $unit-2;
        padding: 0 $unit-7 0 $unit-2;

        h3 {
            font-weight: $font-weight-semi-bold;
            padding: 0;
        }

        .editButtonContainer {
            display: flex;
            flex: 1 0 0;
            justify-content: flex-end;

            .linkButton {
                align-items: center;
                display: flex;
                gap: $unit-half;
            }
        }

        .sectionTitleContainer {
            display: flex;
            flex-direction: column;

            .formSubtitle {
                color: $color-gray-600;
                font-size: $font-size-small;
            }
        }

        .formTitle {
            font-weight: $font-weight-semi-bold;
            padding-bottom: $unit-1;
            padding-left: $unit-1;
            padding-top: $unit-half;
        }

        .sectionNumber {
            align-items: center;
            background-color: $color-gray-800;
            border-radius: 100%;
            color: $color-white;
            display: flex;
            float: none;
            font-size: $font-size-base;
            font-weight: bold;
            height: 20px;
            justify-content: center;
            line-height: 1;
            padding: 3px 7px;
            text-align: center;
            vertical-align: middle;
            white-space: nowrap;
            width: 20px;
        }

        .checkIcon {
            align-items: center;
            display: flex;
            font-size: $font-size-small;
        }

        .sectionNumberCompleted {
            background: $color-fg-success-small;
        }

        .badgeActive {
            background: $color-in-progress-600;
        }
    }

    .componentContainer {
        padding: $unit-3 $unit-7 0;

        @media (max-width: 600px) {
            padding: $unit-2 $unit-2 0;
        }
    }
}
