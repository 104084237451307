.labelText {
    font-weight: 700;
    margin-bottom: 5px;
}

.sealedBidOptions {
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    padding: 10px 10px 0;

    h5 {
        margin-bottom: 8px;
    }
}

.subFormButton {
    margin-right: 10px;
}
