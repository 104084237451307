@import '../../../theme/bootstrap-custom-variables';
@import '~@opengov/capital-style/scss/tokens';

.uploadedAttachmentsLabel {
    margin-bottom: 10px;
}

.uploadedAttachments {
    margin-bottom: 25px;

    li {
        border-bottom: 1px solid $list-group-border;
        padding: 10px 15px;

        &:first-child {
            border-top: 1px solid $list-group-border;
        }
    }
}

.noAttachmentsContainer {
    color: $color-gray-700;

    i {
        font-size: $font-size-display-large;
        margin-bottom: $unit-3;
    }

    h3 {
        color: $color-gray-700;
    }
}

.attachmentsContainer {
    font-family: $base-font;
    font-size: $font-size-base;
    line-height: $line-height-default;
    margin-top: $unit-4;

    h4 {
        margin-bottom: $unit-1;
    }
}
