.alert {
    margin-bottom: 15px !important;
    padding-bottom: 5px !important;
    padding-top: 5px !important;
    text-align: center;
}

.details {
    margin-bottom: 40px;
}

.projectTitle {
    margin-bottom: 10px;

    h3 {
        margin-bottom: 3px;
    }
}

.statusLabel {
    margin-right: 15px;
}

.subscribeButtonContainer {
    float: right;
    padding-bottom: 5px;
    padding-left: 10px;

    .reportsButton {
        vertical-align: top;
    }
}
