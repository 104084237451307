@import '../../../theme/bootstrap-custom-variables';

.contractButton {
    margin-top: 10px;
}

.logo {
    float: left;
    margin-right: 15px;
    width: 80px;
}

.proposalButton {
    margin-bottom: 5px;
}

.vendorInfo {
    margin-top: 10px;
}

.vendorWebsite {
    margin-top: 3px;
}

@media (max-width: $screen-xs-max) {
    .logo {
        display: inline-block;
        float: none;
        margin-right: 0;
    }

    .vendor {
        text-align: center;
    }
}
